/* Center the logo */
.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    position: relative; /* Allows positioning elements like the button */
    margin: 0;
    padding: 0;
  }
  
  /* Style for the Sign Up button */
  .sign-up-button {
    position: absolute;
    top: 20px;
    right: 20px; /* Position the button in the top-right corner */
    padding: 10px 20px;
    background-color: #ffffff; /* White background */
    color: #009688; /* Text color matches the background */
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 20px; /* Rounded corners */
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Attractive shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add hover effects */
  }
  
  /* Hover effect for the button */
  .sign-up-button:hover {
    transform: scale(1.1); /* Slightly enlarge the button on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Enhance the shadow */
  }
  
  body, html {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  