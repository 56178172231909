.sidebar-container {
  justify-content: space-evenly;
  padding: 10px;
  font-size: 1.3rem;
  height: 100vh;
  width: 15vw;
  /* background-color: var(--slate-color); */
  background-color: #53565a;
  color: var(--primary-color);
}

.sidebar-logo {
  width: 100%;
  height: 20%;
  object-fit: cover;
}

.sidebar-content,
.sidebar-content-mobile {
  height: 100%;
  justify-content: space-evenly;
  font-size: 1.3rem;
  font-weight: 500;
}

.sidebar-content-mobile {
  display: none;
}

.ham-icon {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  display: none;
}

.ham-icon:hover {
  cursor: pointer;
}

.regular-heading {
  display: flex;
  font-size: 1.7rem;
  font-weight: 500;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.menu-icons:hover,
.menu-expanded:hover {
  cursor: pointer;
}

.menu-expanded {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 1.3rem;
  text-transform: capitalize;
  width: 100%;
}

@media screen and (max-width: 700px) {
  .ham-icon {
    display: flex;
  }
  .regular-heading {
    display: none;
  }
  .sidebar-content-mobile {
    display: flex;
  }
  .sidebar-content {
    display: none;
  }
}