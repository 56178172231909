body {
  background-color: #009CA6 !important;
}

.logo-container {   
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8rem;
    font-weight: bold;
    font-family: 'Arial', sans-serif;
    color: #ffffff;
    overflow: hidden;
  }
  
  .letter {
    opacity: 0;
    transform: scale(0.5);
    animation: fadeInUp 5s forwards;
    margin: 0 5px;
    animation-iteration-count: infinite;  /* Set animation to loop infinitely */
  } 
  
  .letter.c { animation-delay: 0.2s; }
  .letter.y { animation-delay: 0.4s; }
  .letter.i { animation-delay: 0.6s; }
  .letter.e { 
    animation-delay: 0.8s;
  }
  .letter.n { animation-delay: 1.0s; }
  .letter.t { animation-delay: 1.2s; }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    50% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(20px);
    }
  }
  