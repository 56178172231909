.proper-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  width: 100%;
  min-height: max-content;
  padding: 1vw;
}

.proper-div > * {
  font-family: 'Rubik', sans-serif;
}

.dash-default-container {
  min-height: 100vh;
}

.dash-default-heading {
  font-size: 2.8rem;
  font-weight: 600;
  letter-spacing: 2px;
}

.dash-default-inner-container {
  padding: 15px;
  border-radius: 15px;
}

.dash-default-inner-container-heading {
  font-size: 1.6rem;
  font-weight: 500;
}

.dash-default-firstcontainer-card-container {
  flex-direction: row;
  width: 80%;
  align-items: center;
  justify-content: space-evenly;
}

.dash-default-firstcontainer-card-container > div {
  width: 20%;
}

.dash-default-firstcomp {
  flex-direction: row;
  justify-content: space-between;
  height: min-content;
  width: 80%;
}

.robocontrol-container {
  justify-content: center;
  gap: 30px;
}

.robocontrol-heading {
  color: var(--tertiary-color);
}

.robocontrol-dropdown {
  border: none;
  background-color: var(--tertiary-color);
  color: var(--primary-color);
  font-size: 1.75rem;
  padding: 0.5rem 1rem;
}

.Robo {
  height: 100%;
}

.robocontrol-outerbox {
  width: 100%;
}

#operate-img {
  width: 30%;
}

.robocontrol-list{
  flex-direction: column;
  gap: 30px;
  height: max-content;
}

/* DashProfile  */
.dashprof-wrapper{
  padding: 30px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5vh;
}

.dashprof-heading {
  font-size: 2.8rem;
  font-weight: 600;
  letter-spacing: 2px;
}

.dashprof-form {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  gap: 50px;
  padding: 20px;
}

.dashprof-fields {
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.dashprof-fields input {
  border: none;
  color: inherit;
  background-color: inherit;
  border-radius: 10px;
  padding: 8px;
  width: 40%;
  height: 36px;
  border: 1px solid var(--tertiary-color);
  font-size: 1rem;
}

.dashprof-fields input:focus {
  border: 2px solid var(--tertiary-color);
  box-shadow: 0;
}

.dashprof-indi {
  width: 40%;
  text-align: end;
}

.profile-submit {
  padding: 0.5rem 2rem;
  border: 0;
  border-radius: 1rem;
  background-color: var(--tertiary-color);
  color: var(--primary-color);
  font-size: 1.2rem;
  font-weight: 500;
  font-family: 'Rubik', sans-serif;
  transition: all 0.5s ease-in-out;
}

.profile-submit:hover {
  color: var(--supplementary-color);
  transform: scale(0.9);
}

/* Dash Operate */

.dash-buttons {
  display: flex;
  flex-direction:row;
  justify-content: space-around;
  gap: 1rem;
}

.dash-operate-button {
  flex-grow: 1;
  flex-shrink:1;
  flex:3;
  border-radius: 10px;
  padding: 0.4em 1em;
  font-size: 1rem;
  background: white;
  font-weight: bold;
  color: var(--tertiary-color);
  cursor: pointer;
  margin: 10px 0;
}

.dash-operate-button:hover{
  flex:5;
}

.authenticate-ms-button{
  border-radius: 10px;
  padding: 0.4em 1em;
  font-size: 1rem;
  background: white;
  font-weight: bold;
  color: var(--tertiary-color);
  cursor: pointer;
  margin: 10px 0;
}
.authenticate-ms-button:hover{
  transform: scale(1.1);
}

.dash-operate-container {
  padding: 20px;
  justify-content: flex-start;
  height: 100%;
}

.dash-operate-operation {
  height: 80%;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
}

.dash-operate-list-container {
  height: 50%;
  justify-content: space-evenly;
}

.dash-operate-list-dropdown {
  border: 0;
  padding: 0.3rem 0.8rem;
  font-family: 'Rubik', sans-serif;
  font-size: 1.5rem;
  background-color: var(--tertiary-color);
  color: var(--primary-color);
  border-radius: 5px;
}

#operate-img {
  width: 33%;
}

/*meetlinks*/

.meet-card-container {
  width: 100rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; /* Optional: Adds space between cards */
  overflow-y: scroll;
}

.meet-card {
  width: 100%; /* Default for small screens */
  padding: 10px;
}

/* Video Control */
.video-control-keypads {
  padding: 0;
  height: 27vh;
  border: 1px solid red;
  position: relative;
  z-index: 1; /* Ensure it's above other elements */
  display: flex;
  flex-direction: column;
  justify-content:baseline;
  align-items: flex-end;
}

/* Draggable Control Panel */
.draggable {
  position: absolute;
  height: 250px;
  bottom: 10px;
  left: 10px;
  width: 180px;
  padding: 4px;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1000; /* Ensures it's always on top */
}

/* Drag Handle */
.drag-handle {
  cursor: grab;
  background: lightgray;
  padding: 2px;
  text-align: center;
  font-weight: bold;
  border-radius: 5px;
}

.drag-handle:active {
  cursor: grabbing;
}

/* Video Controls Container */
.video-control-meet,
.dash-operate-video-container {
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
}

/* Video Control Buttons */
.video-control-control {
  position: relative;
  z-index: 5;
  top: 10px;
  left: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 2rem;
  border: 1px solid lightgray;
}

.camera-control-control {
  position: relative;
  z-index: 5;
  left: 10px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 0rem;
  border: 1px solid lightgray;
}

/* Button Styling */
.Keybutton {
  padding: 8px 12px;
  border: none;
  background: #007bff;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

/* Button Active State */
.Keybutton:active {
  background-color: #53565a;
}

/* Circle Button Effect */
.camera-control-button > button,
.video-control-control > button {
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-control-meet > iframe {
  padding: 10px;
  width: 100%;
  border-radius: 15px;
}

.teams-meet-container .wrapper {
  height: 110vh;
  text-align: center;
}

.ms-Button-label {
  overflow-y: hidden;
}

.teams-meet-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 7vh;
}

.teams-meet-header h1 {
  flex:3;
}

.teams-meet-header div {
  padding-left:0.5rem;
  display: flex;
  gap: 1rem;
  flex-direction: row;
  align-items: center;
}

.battery {
  width: 6rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: solid thin #000000;
  position: relative;
}

.battery:after {
  content: " ";
  top: 30px;
  right: -7px;
  width: 7px;
  position: absolute;
  background: #000000;
}

.bar {
  cursor: pointer;
  display: inline-block;
  width: 0.5rem;
  border: solid thin rgb(0, 0, 0);
  height: 30px;
  background: transparent;
  transition: background 1s;
}

.bar.active {
  background: limegreen;
}

.f38x3r3 {
  margin-left: 0 !important; 
}

.fehpkvd {
  margin-left: 0 !important;
}

.f1l02sjl {
  height: auto !important;
}

.heading,
.ms-Stack,.ms-TextField, .ms-TextField-fieldGroup, .ms-TextField-suffix
.ms-StackItem,.css-415,.css-429, .root-448, .css-556, .css-560, .css-561, .ms-Rating-star, .css-524 {
  overflow-y: hidden !important;
}

@media (min-width: 768px) {

  .meet-card {
    width: 48%;
  }
}
@media (max-width: 768px) {

  .meet-card-container {
    padding-top: 10rem;
  }
}

@media screen and (max-width: 576px) {
  .dash-buttons {
    flex-direction: column;
  }

  .meet-card-container {
    width: 100;
    border: 2px solid red;
  }

  .dash-operate-button {
    flex-shrink: 0;
    flex-grow: 0;
  }
}