.signin_background {
  background: url("../../assets/ubot/Innovation-cockpit-for-Ubot-GUI.jpg");
  background-size: cover; /* Ensures the image scales down proportionally */
  background-position: bottom; /* Centers the image */
  background-repeat: no-repeat; /* Prevents tiling */
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  position: absolute;
  bottom: 0;
  z-index: -10;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  /* overflow-y: hidden; */
}



.sign_in_page {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
}

.signin_bg {
  display: none;
}

/* .signin_container {
  display: flex !important;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  min-height: 60vh;
  background-color: var(--primary-color);
  color: var(--primary-color);
  border-radius: 10px;
  max-width: 60vw;
} */

.signin_container form {
  width: 100%;
  border: 1px solid #dfdfdf;
  border-radius: 15px;
  background: #fff;
  text-align: center;
}

.signin_container p {
  color: red;
}

.signin_container button {
  border-radius: 12px;
  padding: 0.4em 2em;
  background: var(--secondary-color);
  font-weight: bold;
  color: #000000;
  cursor: pointer;
  margin: 10px 0;
}

#loginpage-img {
  width: 50%;
}

#signin-button:hover {
  cursor: pointer;
  background-color: var(--tertiary-color);
  color: var(--secondary-color);
}

.form-button:hover {
  cursor: pointer;
  background: var(--slate-color);
  color: var(--secondary-color);
}

/* New design of signin page  */
.signin_container {
  width: 75vw;
  min-height: 73vh;
  /* background-color: var(--primary-color); */
  background-color: transparent;
  border: none;
  border-radius: 25px;
  margin: 10vh auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  color: var(--tertiary-color);
}

.signin_container * {
  overflow-x: visible;
}

.signin_container input,
.signin_container label {
  /* color: var(--tertiary-color); */
  color: black;
}

.form-wrapper {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 30%;
  padding: 1rem;
  border-radius: 20px;
  gap: 1rem;
  border: 2px solid;
  border-color: rgb(225,225,2225,0.4);
  background: rgba(227, 223, 223, 0.3); /* Semi-transparent white */
  backdrop-filter: blur(5px); /* Applies the blur effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari support */
}

#eye-button {
  background-color: inherit;
  color: inherit;
  padding: 0 10px;
  margin-left: 10px;
}

.log-sign-heading {
  font-size: 1.5rem;
  font-weight: 500;
  font-family: 'Rubik', sans-serif;
  margin-bottom: 10px;
  color: var(--slate-color);
}

#error_display {
  color: red;
  width: 90%;
  padding: 5px 10px;
  z-index: 100;
  font-family: 'Rubik', sans-serif;
}

@media screen and (max-width: 900px) {
  .signin_container {
    flex-direction: column;
  }
  #loginpage-img {
    width: 100%;
  }
  .form-wrapper {
    width: 100%;
  }
  .signin_container form {
    width: 60%;
  }
  .signin_container input[type="text"],
  .signin_container input[type="password"] {
    font-size: medium;
  }
}
